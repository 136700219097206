.optionstyle {
  border: 1px solid rgba(0, 0, 0, 0.53);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
}
select::after {
  border: 2px solid white;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
}
.optStyle {
  border: 2px solid white;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
}

ul.pagination {
  justify-content: center;
  color: black;
  background: none;
  --bs-pagination-bg: none;
  --bs-pagination-padding-x: 0;
  --bs-pagination-padding-y: 0;
  --bs-pagination-active-bg: none;
  --bs-pagination-color: none;
  --bs-pagination-hover-color: white;
  --bs-pagination-hover-color: none;
  --bs-pagination-focus-color: none;
  --bs-pagination-focus-bg: none;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: none;
  --bs-pagination-hover-bg: none;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  width: 60px;
  display: flex;
  justify-content: center;
  font-size: 25px;
}

ul.pagination li a {
  text-decoration: none;
  padding: 5px;
  border: 0;
  font-size: 20px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #c73866;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #c73866;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
