body {
  font-family: "Raleway", sans-serif;
  font-family: "Sansita Swashed", cursive;
  
}
h5{
  font-size: 1rem !important;
  font-weight: 500 !important;
}

/* button{
    background: #C73866;
    color: white;
} */
.btnHover {
  font-size: 20px;
  font-weight: 500;
  background: #c73866;
  width: 252px;
  color: white;
}
.btnHover:hover {
  background: white;
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.common-user-detail-card .carousel-item {
  overflow: hidden;
}