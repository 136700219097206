.viewButton{
    color: #c73866;
    &&:hover{
        cursor:pointer;
    }
}
.closeButton{
    background-color: #c73866;
    color: white;
    border-radius: 8px;
    border: 1px solid;
    &&:hover{
        cursor:pointer;
        background-color: #c73866;
        color: aliceblue;
    }
}
.backGroundColor{
    background-color: #c73866;
}
.primaryTextColor{
    color: #c73866;
}
.deleteButton{
    width: 20%;    
    padding: 10px;
    border: 1px solid;
    background-color: rgb(199, 56, 102);
    border-radius: 8px;
    color: white;


    
    
}