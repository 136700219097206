.applogo {
  font-family: "Sansita Swashed", sans-serif;
  font-weight: 400;
  font-size: 34px;
}
.mainDiv {
  background: #ffbd71;
  /* min-width: 340px; */
  min-height: 100vh;
}
a li {
  min-width: 260px;
}
a.active li {
  background: #c73866;
  border: 1px solid #384754;
  border-radius: 10px;
}
a.active li span {
  color: white;
}
